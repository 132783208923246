<template>
  <div
    class="verify-code animate__animated"
    :class="visiable ? ['animate__bounceInLeft', 'active'] : []"
  >
    <i @click="onClose" class="iconfont icona-chutubiao close"></i>
    <form @submit.prevent="onSubmit">
      <div class="title">验证</div>
      <div @click="onRefersh" ref="domVerify" class="verify-img"></div>
      <input
        type="text"
        :value="value"
        @input="$emit('input', $event.target.value)"
        placeholder="请输入验证码"
      />
      <button class="submit-btn">确定</button>
    </form>
  </div>
</template>

<script>
export default {
  name: "VerifyCode",
  props: {
    value: {
      type: String,
      default: "",
    },
    visiable: {
      type: Boolean,
      default: false,
    },
    verifyImg: {
      type: String,
      default: "",
    },
  },
  model: {
    prop: "value",
    event: "input",
  },
  methods: {
    onSubmit() {
      this.$emit("click");
    },
    onClose() {
      this.$emit("close");
    },
    onRefersh() {
      this.$emit("refersh");
    },
  },
  watch: {
    verifyImg: {
      handler(newVal) {
        this.$nextTick(() => {
          this.$refs.domVerify.innerHTML = newVal;
        });
      },
    },
  },
};
</script>

<style lang="less" scoped>
.verify-code {
  width: 345px;
  height: 219px;
  background: #161f2c;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.09);
  opacity: 1;
  border-radius: 10px;
  z-index: 15;
  position: absolute;
  top: 265px;
  left: -999px;
  box-sizing: border-box;
  padding: 15px;

  .close {
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 14px;
    color: #e9ecf0;
  }

  form {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;

    .title {
      font-size: 18px;
      font-family: "PingFang SC";
      font-weight: bold;
      color: #e9ecf0;
    }

    .verify-img {
      width: 250px;
      height: 92px;
      background: #c1c1c1;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.09);
      opacity: 1;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    input {
      width: 251px;
      height: 30px;
      background: #0f151f;
      opacity: 1;
      border-radius: 5px;
      font-size: 14px;
      color: #ffffff;
      border: none;
      text-align: center;
    }

    .submit-btn {
      font-size: 16px;
      font-family: "PingFang SC";
      font-weight: 400;
      color: #27cc90;
      align-self: flex-end;
      border: none;
      background-color: transparent;
    }
  }
}

.active {
  left: 15px;
}
</style>
